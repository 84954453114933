:root {
  font-size: 16px;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--one-color-cobas-gray-50) !important;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

*::-webkit-scrollbar {
  width: 0.75rem;
  background: var(--one-color-gray-50);
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--one-color-gray-400);
  border-radius: 0.375rem;
  border: 3px solid var(--one-color-gray-50);
}
.owcmodalZIndex {
  position: relative;
  z-index: 10001;
}
.owcoverlayZIndex {
  z-index: 10001;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spin {
  animation-name: spin;
  animation-duration: 5000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
